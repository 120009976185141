.side-nav {
  background-color: transparent;
  padding-right: 5px;
  padding-left: 5px;
}

.side-nav-list-container {
  overflow-y: scroll;
  overflow-x: hidden;
  background-color: transparent;

  .side-nav-list-tile {
    display: flex;
    align-content: flex-start;
    align-items: center;
    overflow: hidden;
    height: 50;
    padding: 0;
    padding-left: 5px;
    img {
      flex-shrink: 0;
      object-fit: cover;
    }

    div {
      display: flex;
      flex-direction: column;
      flex-grow: 4;
      text-align: start;
      padding-left: 5px;
    }
  }
}

.route-container {
  padding-top: 45px;
}
