.main-tile {
  margin-top: 25%;
  margin-bottom: 25%;
  img.logo {
    object-fit: contain;
  }
}

.container {
  height: 100vh;
  .top-row {
    height: 25%;
  }
  .body-row {
    height: 50%;
  }
  .bottom-row {
    height: 20%;
  }
  .footer-row {
  }
}

.iframe-container {
  display: flex;
  flex-direction: column;
  height: 60vh;
  iframe {
    flex: 1;
    border: none;
    margin: 0;
    padding: 0;
  }
}
