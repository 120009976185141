.item-hero-image {
  mask-image: linear-gradient(rgba(0, 0, 0, 1), rgba(0, 0, 0, 1), rgba(0, 0, 0, 0));
  box-shadow: 0 0 10px 5px white;
  width: 100%;
  z-index: -1;
}

.item-primary-image-tile {
  height: 30vh;
  display: flex;
  border-radius: 10px;
  -moz-border-radius: 10px;
  -webkit-border-radius: 10px;
  overflow: hidden;
  img {
    object-fit: contain;
    min-width: 100%;
    min-height: 100%;
    border-radius: 10px;
    -moz-border-radius: 10px;
    -webkit-border-radius: 10px;
  }
}

.description-tile {
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  overflow: hidden;
}

.simple-info-tile {
  max-height: 90px;
  h4 {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}

.images-tile {
  overflow-x: auto;
  overflow-y: hidden;
  white-space: nowrap;
  padding: 0;
}

.barcode-tile {
  flex-grow: 0;
  flex-shrink: 0;
  .barcode-row {
    .barcode {
      flex: 0 0 auto;
      margin: 10px;
    }
  }
}
.barcode {
  width: 100%;
  height: 100%;
}

.image-thumb {
  margin: 10px;
  object-fit: cover;
}

.pagination-nav {
  display: flex;
  justify-content: space-evenly;
}

.description-col {
  width: 50%;
}

.container {
  height: 100vh;
}

.sku-tile {
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  overflow: hidden;
}
